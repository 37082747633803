import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/layout";

// markup
const IndexPage = () => {
  return (
    <Layout title="Lovely Ladyverse - About">
      <h1 class="flex-pageheading">About</h1>
      <p class="flex-textbox">The Lovely Ladyverse is a setting I (Digital Poppy Poppy Zone) have dedicated slightly too much of my time, energy and grey matter too. It's a deranged amalgamation of all of my favourite things: History, politics, fiction and women. I hope that you enjoy it very much, and if not I hope instead that you have a *terrible* day :)</p>
      <p class="flex-textbox s"><a className="header-link" href="https://www.poppy-zone.com">Click here to visit 'The Poppy Zone'</a></p>
    </Layout>
  );
};

export default IndexPage;
